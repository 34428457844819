<template>
  <ion-list class="comments ion-no-padding">
    <ion-item v-if="commentsFilter" lines="none">
      <ion-button @click="toggleCommentsFilter" fill="clear" class="btn btn-link comments--old">
        Régebbi hozzászólások
      </ion-button>
    </ion-item>

    <Comment :key="comment.id" :postId="post.id" :comment="comment" v-for="comment in filteredComments" />

    <ion-item class="comment" lines="none" v-if="activePet">
      <ion-avatar class="comment__avatar" slot="start">
        <ion-img :src="activePet.avatar" :alt="activePet.name" />
      </ion-avatar>
      <form @submit.prevent="submit" @keypress.enter="submit" class="comment__form">
        <ion-input
          v-model="newComment"
          @ionFocus="commentFocus = true"
          @ionBlur="commentFocus = false"
          placeholder="hozzászólás írása"
          autocapitalize="sentences"
        />
        <ion-button
          type="submit"
          fill="clear"
          color="dark"
          shape="round"
          :disabled="!newComment"
          @click="submit"
          class="btn btn-send"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="22" height="21" viewBox="0 0 22 21" fill="none">
            <path
              d="M6.32047 11.6287C7.33792 11.6287 8.36274 11.636 9.38019 11.6287C10.1986 11.6214 10.6557 11.1085 10.5746 10.3025C10.5156 9.73837 10.1175 9.33539 9.54976 9.31341C9.06316 9.29142 8.57655 9.30608 8.08994 9.30608C6.46792 9.30608 4.85328 9.29875 3.23126 9.31341C3.01744 9.31341 2.91422 9.25479 2.82575 9.04964C1.99999 7.08603 1.16687 5.12975 0.326365 3.17347C-0.0275305 2.35285 0.0535706 1.5762 0.599159 0.872821C1.15212 0.162112 1.91889 -0.0943295 2.80363 0.0302278C3.11329 0.0741892 3.43032 0.184093 3.71049 0.323304C9.39493 3.00495 15.072 5.69392 20.7491 8.38289C21.5306 8.74923 21.9214 9.38667 21.9877 10.2219C22.0614 11.1085 21.8108 11.8778 21.0366 12.3907C20.7048 12.6105 20.3215 12.7644 19.9528 12.9329C14.4896 15.4753 9.01892 18.0251 3.54829 20.5822C3.48193 20.6115 3.41558 20.6408 3.35659 20.6847C2.47186 21.2709 1.44703 20.9485 0.849835 20.4576C0.119926 19.8568 -0.204478 18.8897 0.134671 18.0471C0.621278 16.8235 1.17424 15.6292 1.70508 14.4276C2.0811 13.5777 2.47186 12.7277 2.8405 11.8705C2.9216 11.6873 3.01744 11.636 3.20914 11.636C4.24133 11.636 5.2809 11.6287 6.32047 11.6287Z"
              :fill="!commentFocus ? '#EBEBEB' : '#e6224a'"
              style="transition: 0.1s ease-in-out"
            />
          </svg>
        </ion-button>
      </form>
    </ion-item>
  </ion-list>
</template>

<script>
import { defineComponent, computed, ref } from 'vue'
import { IonList, IonItem, IonText, IonLabel, IonAvatar, IonImg, IonInput, IonButton } from '@ionic/vue'
import moment from 'moment'

import httpClient from '@/core/api/api.service'
import usePets from '@/composables/pets'
import usePosts from '@/composables/posts'
import useToast from '@/composables/toast'
import useNavigation from '@/composables/navigation'

import Comment from '@/components/Posts/Comment'

export default defineComponent({
  name: 'Comments',
  components: {
    IonList,
    IonItem,
    IonText,
    IonLabel,
    IonAvatar,
    IonImg,
    IonInput,
    IonButton,
    Comment,
  },
  props: {
    postData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { activePet } = usePets()
    const { feed } = usePosts()
    const { openToast } = useToast()
    const { navigateTo } = useNavigation()

    const MAX_COMMENTS_AMOUNT = 5
    const post = ref({ ...props.postData })
    const isLoading = ref(false)
    const newComment = ref('')
    const commentFocus = ref(false)
    const commentsFilter = ref(
      post.value.comments.length > MAX_COMMENTS_AMOUNT && post.value.pet.id !== activePet.value && activePet.value.id,
    )

    const filteredComments = computed(() => {
      if (!commentsFilter.value) {
        return post.value.comments
      }

      return post.value.comments.filter((item, index) => index > post.value.comments.length - MAX_COMMENTS_AMOUNT - 1)
    })

    async function createComment(postId, params) {
      if (isLoading.value) {
        return
      }

      try {
        isLoading.value = true

        const { data } = await httpClient.save(`posts/${postId}/comments`, params)
        const postData = data.data

        if (feed && feed.posts.length > 0) {
          const currentPost = feed.posts.find(item => item.id === postData.id)

          if (currentPost) {
            Object.assign(currentPost, postData)
          }
        } else if (post.value && post.value.id === postData.id) {
          post.value = postData
        }

        return Promise.resolve()
      } catch (error) {
        return Promise.reject(error)
      } finally {
        isLoading.value = false
      }
    }

    const submit = async () => {
      if (!newComment.value) {
        return
      }

      try {
        await createComment(post.value.id, {
          pet_id: activePet.value.id,
          content: newComment.value,
        })

        newComment.value = ''
      } catch (error) {
        await openToast({
          message: 'Óóó! Sajnos nem tudtuk elküldeni a hozzászólást. Próbáld meg később!',
          color: 'danger',
        })
      }
    }

    const toggleCommentsFilter = () => (commentsFilter.value = !commentsFilter.value)

    const goToProfile = petId => {
      navigateTo({ name: 'pet', params: { id: petId } })
    }

    return {
      moment,
      activePet,
      submit,
      newComment,
      filteredComments,
      toggleCommentsFilter,
      commentsFilter,
      post,
      goToProfile,
      commentFocus,
    }
  },
})
</script>

<style lang="scss" scoped>
.comments {
  @media (max-width: 767px) {
    margin-top: -5px;
  }

  @media (min-width: 768px) {
    margin-top: -15px;
  }

  &--old {
    height: 1rem;
    opacity: 0.6;
  }
}

.btn-send {
  opacity: 1;
}

.comment {
  --min-height: 1em;
  --padding-start: 12px;

  &.pressable {
    cursor: pointer;
  }

  & + & {
    margin-top: 10px;
  }

  &__avatar {
    width: 32px;
    height: 32px;
    margin: 0px 13px 0 0;
    align-self: center;
  }

  &__content {
    font-size: 0.875rem;
  }

  &__meta {
    font-size: 0.625rem;
    color: #444;
    line-height: 1.3;
  }

  &__form {
    width: 100%;
    display: flex;
    margin-right: 12px;

    ion-input {
      --padding-top: 0;
      --padding-bottom: 0;
      height: 30px;
      margin-top: 2px;
      font-size: 14px;
    }
  }

  .btn {
    --padding-start: 0;
    --padding-end: 0;
    width: 25px;
    height: 25px;
    margin: 4px 5px 2px;
  }
}
</style>
