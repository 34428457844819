<template>
  <div class="container">
    <div class="content pet-content">
      <form class="search-bar" @submit.prevent="handleSearch">
        <ion-input
          id="searchFor"
          v-model="searchFor"
          name="searchFor"
          enterkeyhint="go"
          :placeholder="inputLabel"
          class="form-control"
          autocapitalize="sentences"
          @input="handleSearch"
        />
        <ion-img :src="require('@/assets/images/icons/post/search.svg')" alt="search" class="search-button" />
      </form>

      <p class="pet__title">{{ petTitle }}</p>

      <div class="pet__list">
        <div class="pet__list__item" v-for="pet in pets" :key="pet.id" @click="handlePetClick(pet)">
          <ion-img :src="pet.avatar" alt="pet" class="pet__list__item__avatar" />
          <h1 class="pet__list__item__name">{{ pet.name }}</h1>
          <ion-img
            :src="require('@/assets/images/post/check.svg')"
            alt="checkmark"
            class="pet__list__item__check"
            v-show="selectedPets.find(p => p.id === pet.id)"
          />
        </div>
      </div>

      <ion-button
        type="submit"
        id="submit"
        expand="full"
        shape="round"
        strong="true"
        class="btn submit"
        @click.prevent="$emit('onSubmit', selectedPets)"
        :style="selectedPets.length === 0 ? '--background: #333333' : ''"
      >
        {{ selectedPets.length > 0 ? buttonLabel : 'Vissza' }}
        <ion-ripple-effect></ion-ripple-effect>
      </ion-button>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref, watch } from 'vue'
import { IonInput, IonImg, IonRippleEffect } from '@ionic/vue'

import httpClient from '@/core/api/api.service'
import helpers from '@/utils/helpers'

export default defineComponent({
  name: 'PetSelector',
  components: {
    IonInput,
    IonImg,
    IonRippleEffect,
  },
  props: {
    petTitle: {
      type: String,
      default: 'Csak a követőidet tudod megjelölni:',
    },
    inputLabel: {
      type: String,
      default: 'Kivel voltál?',
    },
    buttonLabel: {
      type: String,
      default: 'Kedvencek megjelölése',
    },
    maxPets: {
      type: Number,
      default: 5,
    },
    selectedPetList: {
      type: Array,
      default: [],
    },
  },
  setup({ petTitle, inputLabel, buttonLabel, maxPets, selectedPetList }) {
    const pets = ref([])
    const searchFor = ref('')
    const nextPage = ref(1)
    const hasNextPage = ref(true)
    const selectedPets = ref(selectedPetList)
    const isLoading = ref(false)

    let timeout

    const getPets = async (params, event) => {
      if (isLoading.value) {
        return
      }

      if (!event) {
        nextPage.value = 1
        hasNextPage.value = true
        pets.value = []
      }

      isLoading.value = true

      const reqParams = {
        page: nextPage.value,
        limit: 50,
        ...params,
      }

      try {
        const { data } = await httpClient.get('pets', '', reqParams)

        const petResults = data.data
        const pages = data.pagination

        if (nextPage.value > 1) {
          pets.value = helpers.appendToList(pets.value, petResults)
        } else {
          pets.value = petResults
        }

        if (pages.lastPage > pages.currentPage) {
          nextPage.value = pages.currentPage + 1
        } else {
          hasNextPage.value = !hasNextPage.value
        }

        return Promise.resolve()
      } catch (error) {
        console.warn(error.message)
      } finally {
        isLoading.value = false

        if (event) {
          event.target.complete()
        }
      }
    }

    const search = () => getPets({ filter: 'followers', search: searchFor.value })

    const handlePetClick = pet => {
      if (selectedPets.value.find(p => p.id === pet.id)) {
        selectedPets.value = selectedPets.value.filter(p => p.id !== pet.id)
      } else {
        if (selectedPets.value.length >= maxPets) return

        selectedPets.value = [...selectedPets.value, pet]
      }
    }

    const handleSearch = () => {
      clearTimeout(timeout)
      timeout = setTimeout(search, 1000)
    }

    onMounted(() => search())

    watch(selectedPetList, () => (selectedPets.value = selectedPetList))

    return {
      pets,
      searchFor,
      selectedPets,
      petTitle,
      inputLabel,
      buttonLabel,
      handlePetClick,
      handleSearch,
    }
  },
})
</script>

<style lang="scss" scoped>
.pet-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 80px 0 120px 0;
  position: relative;
}

.search-bar {
  position: relative;
  padding: 0 15px;
  margin-top: 20px;

  ion-input {
    --padding-start: 55px;
    --padding-top: 15px;
    --padding-bottom: 15px;
    border-radius: 28px;
    border: none;
    box-shadow: -7px 11px 29px -10px rgba(0, 0, 0, 0.2);
  }

  .search-button {
    position: absolute;
    left: 35px;
    top: 50%;
    bottom: 0;
    min-height: 17px;
    max-height: 17px;
    color: red;
    transform: translateY(-50%);
    z-index: 3;
  }
}

.pet__title {
  margin-top: 31px;
  color: #b7b7b7;
  text-align: center;
}

.pet__list {
  margin-top: 12px;
  padding: 0 15px;
  display: flex;
  flex-direction: column;

  &__item {
    display: flex;
    white-space: nowrap;
    align-items: center;

    margin-bottom: 16px;

    &:last-of-type {
      margin-bottom: 0;
    }

    &__avatar {
      margin-left: 15px;
      min-width: 42px;
      min-height: 42px;
      max-width: 42px;
      max-height: 42px;
      margin-right: 13px;

      &::part(image) {
        border-radius: 9999px;
      }
    }

    &__name {
      margin: 0;
      overflow: hidden;
      font-size: 14px !important;
      font-weight: 900;
      text-overflow: ellipsis;
      letter-spacing: -0.3px;
      font-family: 'Poppins', sans-serif;
      max-width: calc(100vw - 32px - 30px - calc(23px + 15px + 13px) - calc(22px + 13px));
    }

    &__check {
      margin-left: auto;
      min-width: 22px;
    }
  }
}

.submit {
  --background: #e6224a;
  --box-shadow: none;
  max-width: 274px;
  margin: 0 auto;
  font-size: 16px;
  letter-spacing: -0.64px;
  margin-top: 90px;
  position: fixed;
  bottom: 100px;
  left: 50%;
  transform: translateX(-50%);
}
</style>
