import httpClient from '@/core/api/api.service'

export default function usePois() {
  const getPois = async (search = null) => {
    try {
      const { data } = await httpClient.get('poi', null, { search })
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const getPoi = async id => {
    try {
      const { data } = await httpClient.get(`poi/details/${id}`)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const getPoiBySlug = async slug => {
    try {
      const { data } = await httpClient.get(`poi/slug/${slug}`)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const createPoi = async body => {
    try {
      const { data } = await httpClient.save(`poi`, body)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const editPoiImages = async (id, body) => {
    try {
      const { data } = await httpClient.save(`poi/${id}/edit-images`, body)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const ratePoi = async (id, rating, text = null, image = null) => {
    try {
      const { data } = await httpClient.save(`poi/rate/${id}`, {
        rating,
        text,
        image,
      })
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const visitPoi = async (id, pets = []) => {
    try {
      const { data } = await httpClient.save(`poi/visit/${id}`, {
        pets,
      })
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const contactPoi = async (id, pets = []) => {
    try {
      const { data } = await httpClient.save(`poi/contact/${id}`, {
        pets,
      })
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const webViewPoi = async (id, pets = []) => {
    try {
      const { data } = await httpClient.save(`poi/webview/${id}`, {
        pets,
      })
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const reportPoi = async id => {
    try {
      const { data } = await httpClient.save(`poi/report/${id}`)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const getPresents = async () => {
    try {
      const { data } = await httpClient.get('poi/presents')
      return Promise.resolve(data.presents)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const getPresent = async id => {
    try {
      const { data } = await httpClient.get(`poi/presents/${id}`)
      return Promise.resolve(data.present)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const generatePresentQR = async id => {
    try {
      const { data } = await httpClient.save(`poi/presents/${id}/generate`)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const checkPresentQR = async (id, code) => {
    try {
      const { data } = await httpClient.get(`poi/presents/${id}/check/${code}`)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const submitPresent = async (id, code) => {
    try {
      const { data } = await httpClient.save(`poi/presents/${id}/submit/${code}`)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const sharePresent = async id => {
    try {
      const { data } = await httpClient.save(`poi/presents/${id}/share`)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const claimPresent = async id => {
    try {
      const { data } = await httpClient.save(`poi/presents/${id}/claim`)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const likePoi = async (id, like) => {
    try {
      const { data } = await httpClient.save(`poi/like/${id}`, {
        like,
      })

      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const checkSubmitIsAvailable = async () => {
    try {
      const { data } = await httpClient.get(`poi/check-submit`)

      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  return {
    getPois,
    getPoi,
    getPoiBySlug,
    createPoi,
    editPoiImages,
    ratePoi,
    visitPoi,
    contactPoi,
    webViewPoi,
    reportPoi,
    getPresents,
    getPresent,
    generatePresentQR,
    checkPresentQR,
    submitPresent,
    sharePresent,
    claimPresent,
    likePoi,
    checkSubmitIsAvailable,
  }
}
