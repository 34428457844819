<template>
  <div style="position: relative">
    <ion-button fill="clear" color="dark" @click="menuOpen = true" ref="closeRef">
      <ion-icon :icon="ellipsisVertical" />
    </ion-button>

    <ContextMenu v-if="menuOpen" :post="post" ref="menuRef" />
  </div>
</template>

<script>
import { defineComponent, onBeforeUnmount, onMounted, ref } from 'vue'
import { IonButton, IonIcon } from '@ionic/vue'
import { ellipsisVertical } from 'ionicons/icons'

import ContextMenu from '@/components/Posts/ContextMenu/ContextMenu'

export default defineComponent({
  name: 'ContextMenuButton',
  props: {
    post: {
      type: Object,
      required: true,
    },
  },
  components: {
    IonButton,
    IonIcon,
    ContextMenu,
  },
  setup() {
    const menuRef = ref()
    const closeRef = ref()
    const menuOpen = ref(false)

    const outsideClickHandler = e => {
      if (
        (e.target !== menuRef.value && e.composedPath().includes(menuRef.value)) ||
        e.target.tagName === 'ION-BUTTON'
      ) {
        return
      }

      menuOpen.value = false
    }

    onMounted(() => {
      window.addEventListener('click', outsideClickHandler)
    })

    onBeforeUnmount(() => {
      window.removeEventListener('click', outsideClickHandler)
    })

    return {
      menuRef,
      closeRef,
      menuOpen,
      ellipsisVertical,
    }
  },
})
</script>

<style scoped>
ion-button {
  margin-left: 5px;
  margin-right: -10px;
  --padding-start: 5px;
  --padding-end: 5px;
  font-size: 12px;
}
</style>
