<template>
  <div class="context-menu">
    <div class="context-content">
      <div class="context-item" v-if="!isOwnPost" @click="confirmPostReport">
        <ion-label>Jelentem</ion-label>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
          <path
            d="M7.98332 15.9944C7.03266 15.9944 6.082 15.9889 5.13134 15.9944C4.82557 15.9944 4.58096 15.8944 4.3697 15.6776C3.02432 14.3266 1.67338 12.9757 0.322446 11.6303C0.105629 11.4135 0 11.1577 0 10.8464C0.00555942 8.9451 0.00555942 7.04378 0 5.14246C0 4.83113 0.100069 4.58096 0.322446 4.36414C1.67338 3.01876 3.02432 1.66782 4.3697 0.316887C4.58096 0.10007 4.83113 0 5.13134 0C7.03822 0.00555942 8.9451 0.00555942 10.852 0C11.1633 0 11.4135 0.105629 11.6303 0.328006C12.9646 1.66783 14.3044 3.00764 15.6498 4.34746C15.8721 4.56984 15.9833 4.83113 15.9833 5.14802C15.9778 7.04934 15.9778 8.95066 15.9833 10.852C15.9833 11.1689 15.8777 11.4246 15.6553 11.6525C14.3155 12.9812 12.9812 14.3155 11.6525 15.6553C11.419 15.8888 11.1522 16 10.8186 16C9.87352 15.9889 8.92842 15.9944 7.98332 15.9944ZM7.11605 6.20987C7.11605 6.80473 7.11049 7.39958 7.11605 7.99444C7.11605 8.37248 7.29395 8.64489 7.62752 8.80612C8.2057 9.08409 8.87283 8.66713 8.87839 8.01112C8.88951 6.8214 8.88395 5.62613 8.87839 4.43641C8.87839 4.35302 8.86727 4.26963 8.83947 4.1918C8.71717 3.7804 8.30577 3.51355 7.89993 3.56915C7.44962 3.6303 7.12161 3.96942 7.11605 4.41974C7.10493 5.01459 7.11605 5.61501 7.11605 6.20987ZM7.99444 10.4072C7.35511 10.4072 6.84364 10.9243 6.84364 11.558C6.84364 12.1974 7.37179 12.7199 8.01668 12.7088C8.65045 12.7033 9.16748 12.1807 9.15636 11.5469C9.14524 10.9131 8.63377 10.4072 7.99444 10.4072Z"
            fill="#E6224A"
          />
          <path
            d="M7.116 6.20985C7.116 5.615 7.11044 5.02014 7.116 4.42528C7.12156 3.97497 7.44956 3.63584 7.89987 3.57469C8.30571 3.5191 8.71711 3.78595 8.83942 4.19734C8.86165 4.27518 8.87833 4.35857 8.87833 4.44196C8.87833 5.63167 8.88945 6.82695 8.87833 8.01666C8.87277 8.67268 8.20564 9.08407 7.62746 8.81166C7.28834 8.65044 7.116 8.37803 7.116 7.99999C7.11044 7.39957 7.116 6.80471 7.116 6.20985Z"
            fill="white"
          />
          <path
            d="M7.99455 10.4072C8.63388 10.4072 9.14535 10.9131 9.15647 11.5469C9.16203 12.1807 8.645 12.7033 8.01679 12.7088C7.37189 12.7144 6.84375 12.1974 6.84375 11.558C6.84375 10.9243 7.35522 10.4072 7.99455 10.4072Z"
            fill="white"
          />
        </svg>
      </div>
      <template v-else>
        <div class="context-item" @click="editPost">
          <ion-label>Módosítás</ion-label>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <circle cx="8" cy="8" r="8" fill="#686868"></circle>
            <path
              d="M 4.38 11.624 C 4.265 11.55 4.249 11.51 4.283 11.373 C 4.425 10.801 4.568 10.227 4.713 9.654 C 4.724 9.61 4.749 9.564 4.782 9.532 C 6.109 8.203 7.437 6.875 8.765 5.546 C 8.78 5.531 8.797 5.518 8.807 5.509 C 9.333 6.035 9.857 6.558 10.395 7.096 C 10.377 7.108 10.351 7.119 10.333 7.137 C 9.014 8.455 7.696 9.772 6.377 11.089 C 6.335 11.13 6.277 11.163 6.22 11.178 C 5.65 11.324 5.079 11.466 4.508 11.609 C 4.494 11.612 4.481 11.619 4.469 11.624 C 4.439 11.624 4.409 11.624 4.38 11.624 Z"
              fill="white"
              data-bx-origin="0.614542 0.407413"
            ></path>
            <path
              d="M 11.627 5.615 C 11.616 5.654 11.612 5.696 11.595 5.731 C 11.547 5.828 11.511 5.941 11.439 6.017 C 11.248 6.224 11.038 6.414 10.84 6.608 C 10.323 6.09 9.799 5.567 9.282 5.05 C 9.476 4.852 9.661 4.639 9.871 4.45 C 10.15 4.202 10.616 4.208 10.895 4.458 C 11.086 4.629 11.261 4.816 11.439 5.001 C 11.549 5.115 11.592 5.263 11.627 5.413 C 11.627 5.48 11.627 5.548 11.627 5.615 Z"
              fill="white"
              data-bx-origin="-0.532623 1.594813"
            ></path>
          </svg>
        </div>
        <div class="context-item" @click="confirmPostDelete">
          <ion-label>Törlés</ion-label>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <circle cx="8" cy="8" r="8" fill="#686868"></circle>
            <path
              xmlns="http://www.w3.org/2000/svg"
              d="M9.25877 7.99019L10.9971 9.72847L9.72553 11L7.98724 9.26172L6.27447 10.9745L5.02552 9.72553L6.73828 8.01276L5 6.27447L6.27153 5.00295L8.00981 6.74123L9.75104 5L11 6.24896L9.25877 7.99019Z"
              fill="white"
            />
          </svg>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent } from 'vue'
import { IonContent, IonLabel, alertController } from '@ionic/vue'

import usePets from '@/composables/pets'
import usePosts from '@/composables/posts'
import useNavigation from '@/composables/navigation'
import { POST_EDIT_ROUTE } from '@/constants/routes'

export default defineComponent({
  name: 'ContextMenu',
  props: {
    post: {
      type: Object,
      required: true,
    },
  },
  components: {
    IonContent,
    IonLabel,
  },
  setup(props) {
    const { reportPost, fetchPosts, deletePost } = usePosts()
    const { blockPet, activePet } = usePets()
    const { navigateTo } = useNavigation()

    const isOwnPost = computed(() => props.post.pet.id === activePet.value.id)

    const editPost = async () => {
      navigateTo({ name: POST_EDIT_ROUTE, params: { id: props.post.id } })
    }

    const confirmPostDelete = async () => {
      const dialog = await alertController.create({
        cssClass: 'confirm-dialog',
        message: `<img src="${require('@/assets/images/icons/exclamation-circle.svg')}" class="alert-icon" />
                  <p class="ion-text-center">
                      Biztosan törölni szeretnéd ezt a bejegyzést?
                  </p>`,
        buttons: [
          {
            text: 'Törlés',
            cssClass: 'btn btn-dark btn-block',
            handler: async () => {
              try {
                await deletePost(props.post.id)
                return Promise.resolve()
              } catch (error) {
                return Promise.reject(error)
              }
            },
          },
          {
            text: 'Mégse',
            role: 'cancel',
            cssClass: 'btn btn-link btn-block',
          },
        ],
      })

      return dialog.present()
    }

    const confirmPostReport = async () => {
      const dialog = await alertController.create({
        cssClass: 'confirm-dialog',
        message: `<img src="${require('@/assets/images/icons/exclamation-circle.svg')}" class="alert-icon" /><p class="ion-text-center">Kifogásolható tartalommal találkoztál? Jelentsd nekünk és megvizsgáljuk az esetet!</p>`,
        buttons: [
          {
            text: 'Tartalom jelentése',
            cssClass: 'btn btn-dark btn-block',
            handler: async () => {
              try {
                await reportPost(props.post.id)

                maybeBlockPet()
                return Promise.resolve()
              } catch (error) {
                return Promise.reject(error)
              }
            },
          },
          {
            text: 'Mégse',
            role: 'cancel',
            cssClass: 'btn btn-link btn-block',
          },
        ],
      })

      return dialog.present()
    }

    const maybeBlockPet = async () => {
      const dialog = await alertController.create({
        cssClass: 'confirm-dialog',
        message: `<img src="${require('@/assets/images/icons/vector.svg')}" class="alert-icon" /><p class="ion-text-center">Köszönjük, hogy jelentetted, ha a továbbiakban nem szeretnéd ${
          props.post.pet.name
        } tartalmait látni, letilthatod őt a hírfolyamodban:</p>`,
        buttons: [
          {
            text: 'Felhasználó blokkolása',
            cssClass: 'btn btn-dark btn-block',
            handler: async () => {
              try {
                await blockPet(props.post.pet.id)
                await fetchPosts()

                return Promise.resolve()
              } catch (error) {
                return Promise.reject(error)
              }
            },
          },
          {
            text: 'Mégse',
            role: 'cancel',
            cssClass: 'btn btn-link btn-block',
          },
        ],
      })

      return dialog.present()
    }

    return {
      confirmPostReport,
      activePet,
      isOwnPost,
      navigateTo,
      POST_EDIT_ROUTE,
      editPost,
      confirmPostDelete,
    }
  },
})
</script>

<style lang="scss" scoped>
.inner-scroll {
  overflow: hidden;
}

.context-menu {
  position: absolute;
  top: 32px;
  right: 2px;
  z-index: 10;
}

.context-content {
  background: rgba(0, 0, 0, 0.57);
  border-radius: 23px 4px 23px 23px;
  padding: 13px 15px 17px 30px;
  text-align: right;
  color: #fff;

  .context-item {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    svg {
      margin-left: 12px;
      height: 16px;
    }

    &:not(:last-of-type) {
      margin-bottom: 11px;
    }
  }
}

ion-label {
  display: block !important;
  font-size: 14px !important;
  font-weight: 800 !important;
  font-family: 'Open Sans', sans-serif !important;
}
</style>
